import { tns } from 'tiny-slider/src/tiny-slider';
import { parseDomArgs } from '../utils/parseDomArgs';

class XScroll {
  constructor(el) {
    const defaultOptions = {
      offset: false,

      // The `theme` refers to the number of visible items in the xscroller at one time. Available
      // themes include:
      //  - 'default' Two and a half items on desktop, one on mobile.
      //  - 'people' Four items on deskotp, responsively cascading down.
      theme: 'default',
    };

    this.options = parseDomArgs(el.dataset, defaultOptions, 'xscroll');

    this.xscrollEl = el.querySelector('[data-xscroller]');
    this.controls = el.querySelectorAll('button[data-xscroll-nav]');
    this.xscroll = null;

    // If there's no scroller element, or the scroller has already been initialized, get out.
    if (!this.xscrollEl || this.xscrollEl.classList.contains('tns-slider')) {
      return;
    }

    if (this.options.offset && window.innerWidth > 768) {
      this.addOffset();
    }

    if (this.options.alignheights) {
      this.alignHeights();
    }

    this.init();
  }

  addOffset() {
    const firstSlide = this.xscrollEl.firstElementChild;

    if (!firstSlide) {
      return;
    }

    const clone = firstSlide.cloneNode();
    clone.removeAttribute('id');
    clone.classList.remove('tns-slide-active');
    this.xscrollEl.appendChild(clone);
  }

  /**
   * If the `alignheights` option is set, make each element's heights consistent.
   *
   * @return void
   */
  alignHeights() {
    let maxHeight = 0;
    const inners = this.xscrollEl.querySelectorAll(this.options.alignheights);

    inners.forEach(el => {
      el.removeAttribute('style');
      const { height: innerHeight } = el.getBoundingClientRect();
      if (innerHeight > maxHeight) {
        maxHeight = innerHeight;
      }
    });

    if (maxHeight > 0) {
      inners.forEach(el => (el.style.height = `${maxHeight}px`));
    }
  }

  getScrollerOptions() {
    const opts = {
      mouseDrag: true,
      autoHeight: false,
      container: this.xscrollEl,
      loop: false,
      nav: false,
      controls: false,
      items: 1.25,
      responsive: {
        768: {
          items: 1.5,
        },
        1200: {
          items: 2.4,
        },
      },
    };

    if (this.options.theme === 'people') {
      opts.items = 1.75;
      opts.responsive = {
        768: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      };
    }

    return opts;
  }

  init() {
    const tnsSettings = this.getScrollerOptions();
    this.xscroll = tns(tnsSettings);
    this.setupControls();
    this.xscroll.events.on('indexChanged', this.updateControls.bind(this));
  }

  setupControls() {
    this.controls.forEach(btn =>
      btn.addEventListener('click', () => this.xscroll.goTo(btn.dataset.xscrollNav)),
    );
  }

  updateControls({ items, slideCount, index }) {
    if (index === 0) {
      this.controls.forEach(btn => {
        if (btn.dataset.xscrollNav === 'prev') {
          btn.setAttribute('disabled', true);
        } else {
          btn.removeAttribute('disabled');
        }
      });
    } else if (index + items >= slideCount) {
      this.controls.forEach(btn => {
        if (btn.dataset.xscrollNav === 'next') {
          btn.setAttribute('disabled', true);
        } else {
          btn.removeAttribute('disabled');
        }
      });
    } else {
      this.controls.forEach(btn => btn.removeAttribute('disabled'));
    }
  }
}

export default XScroll;
