class ExpandToggle {
  constructor(el) {
    if (!el) {
      return;
    }

    const toggle = el.querySelector('.js-expandable-toggle');
    const content = el.querySelector('.js-expandable-content');

    if (!toggle || !content) {
      return;
    }

    this.toggle = toggle;
    this.content = content;

    this.init();
  }

  init() {
    this.toggle.addEventListener('click', this.toggleContent.bind(this));
  }

  deinit() {
    if (this.toggle) {
      this.toggle.removeEventListener('click', this.toggleContent.bind(this));
    }
  }

  toggleContent() {
    if (this.toggle.innerHTML === 'Show more') {
      this.content.classList.add('shown');
      this.toggle.innerHTML = 'Show less';
    } else {
      this.content.classList.remove('shown');
      this.toggle.innerHTML = 'Show more';
    }
  }
}

export default ExpandToggle;
