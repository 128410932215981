/**
 * Handle scrolls to specific points on the page.
 */
class SpecialtyScrolls {
  constructor() {
    // Anchors that have an href of "#top" should smooth scroll to the top of the page.
    this.linksToTop = [];
  }

  /**
   * Get and set all of the specialty links and init them.
   *
   * @return void
   */
  init() {
    this.linksToTop = document.querySelectorAll('a[href="#top"]');
    this.linksToTop.forEach(el => el.addEventListener('click', this.scrollToTop));
  }

  /**
   * Remove event listeners and reset the array of specialty links.
   *
   * @return void
   */
  deinit() {
    this.linksToTop.forEach(el => el.removeEventListener('click', this.scrollToTop));
    this.linksToTop = [];
  }

  /**
   * Smooth scroll the page up to the top.
   *
   * @param {object} event Click event data
   *
   * @return void
   */
  scrollToTop(event = null) {
    if (typeof event === 'object') {
      event.preventDefault();
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

export default SpecialtyScrolls;
