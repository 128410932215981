/**
 * Parse args from a DOM element's `data-` attribute properties.
 *
 * @param {object} args        A map of arguments, assumed to have been mapped from a DOM element's
 *                             `dataset` property.
 * @param {object} defaultArgs Default options.
 * @param {object} dataKey     The prepended key from the DOM element's data attributes.
 *
 * @return object
 */
export const parseDomArgs = (args, defaultArgs, dataKey) => {
  const domArgs = Object.keys(args)
    .filter(k => k.indexOf(dataKey) === 0)
    .reduce((obj, k) => {
      const key = k.replace(dataKey, '').toLowerCase();
      let val = args[k];

      if (val === 'true') {
        val = true;
      } else if (val === 'false') {
        val = false;
      }

      return { ...obj, [key]: val };
    }, {});

  return { ...defaultArgs, ...domArgs };
};
