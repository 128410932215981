class Masonry {
  constructor(el) {
    this.grid = el;

    if (el.children.length < 1) {
      console.warn('The masonry parent has no children.');
      return;
    }

    this.gridChildren = Array.from(el.children);

    this.resize();
  }

  resize() {
    this.gridChildren.forEach(child => {
      const wrapper = child.children;

      if (wrapper.length < 1) {
        return;
      }

      const { height } = wrapper[0].getBoundingClientRect();
      const rowHeight = 20;
      const rowGap = 20;
      const rowSpan = Math.ceil((height + rowGap) / (rowHeight + rowGap));
      child.style.gridRowEnd = `span ${rowSpan}`;
    });
  }
}

export default Masonry;
