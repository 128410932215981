/**
 * Handles all the menu overlays and triggers, including the main nav and search.
 */
class Menu {
  constructor() {
    this.body = document.body;
    this.menuToggles = document.querySelectorAll('.js-toggle-menu');
    this.searchToggles = document.querySelectorAll('.js-toggle-search');

    // State.
    this.menuOpen = false;
    this.searchOpen = false;
    this.escListener = false;

    // Bound methods.
    this.boundToggleMenu = this.toggleMenu.bind(this);
    this.boundToggleSearch = this.toggleSearch.bind(this);
    this.boundEscHandler = this.escHandler.bind(this);
  }

  /**
   * Init the menu toggles and add event listeners.
   *
   * @return void
   */
  init() {
    this.menuToggles.forEach(el => el.addEventListener('click', this.boundToggleMenu));
    this.searchToggles.forEach(el => el.addEventListener('click', this.boundToggleSearch));
  }

  /**
   * Remove event listeners.
   *
   * @return void
   */
  deinit() {
    this.menuToggles.forEach(el => el.removeEventListener('click', this.boundToggleMenu));
    this.searchToggles.forEach(el => el.removeEventListener('click', this.boundToggleSearch));
  }

  /**
   * Toggles the active menu class, removing the active search class in the process. Also adds a
   * listener for the "esc" key to hide the menu.
   *
   * @return void.
   */
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.searchOpen = false;
    this.body.classList.remove('js-menu-search-open');
    this.body.classList.toggle('js-menu-open');

    // If the menu is open, set up the listener for tbhe esc key. Otherwise, remove that listener.
    if (this.menuOpen && !this.escListener) {
      this.addEscListener();
    } else if (!this.menuOpen) {
      this.removeEscListener();
    }
  }

  /**
   * Toggles the active searc class, removing the active menu class in the process. Also adds a
   * listener for the "esc" key to hide the search.
   *
   * @return void.
   */
  toggleSearch() {
    this.searchOpen = !this.searchOpen;
    this.menuOpen = false;
    this.body.classList.remove('js-menu-open');
    this.body.classList.toggle('js-menu-search-open');

    // If the searc is open, set up the listener for tbhe esc key. Otherwise, remove that listener.
    if (this.searchOpen && !this.escListener) {
      this.addEscListener();
    } else if (!this.searchOpen) {
      this.removeEscListener();
    }
  }

  /**
   * Closes both the menu and the search, and removes the listener for the "esc" keypress.
   *
   * @return void
   */
  closeAll() {
    this.menuOpen = false;
    this.searchOpen = false;
    this.body.classList.remove('js-menu-search-open');
    this.body.classList.remove('js-menu-open');
    this.removeEscListener();
  }

  /**
   * Handler for the `keydown` event, listening for a keypress of the "esc" key. On this event, we
   * should close the menu and search overlays.
   *
   * @param {Object} event Event object.
   * @param {string} event.key The key pressed.
   *
   * @return void
   */
  escHandler({ key }) {
    if (key === 'Escape') {
      this.closeAll();
    }
  }

  /**
   * Attach the listener for the "esc" keypress.
   *
   * @return void
   */
  addEscListener() {
    document.addEventListener('keydown', this.boundEscHandler);
    this.escListener = true;
  }

  /**
   * Remove the listener for the "esc" keypress.
   *
   * @return void
   */
  removeEscListener() {
    document.removeEventListener('keydown', this.boundEscHandler);
    this.escListener = false;
  }
}

export default Menu;
