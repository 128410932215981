import { tns } from 'tiny-slider/src/tiny-slider';
import { parseDomArgs } from '../utils/parseDomArgs';

class Carousel {
  constructor(el) {
    const defaultOptions = {
      items: 1,
      center: true,
      mouseDrag: true,
      autoHeight: false,
      responsive: {
        768: {
          items: 2,
        },
      },
    };

    this.options = parseDomArgs(el.dataset, defaultOptions, 'carousel');

    this.el = el;
    this.carouselEl = el.querySelector('[data-carousel]');
    this.carousel = null;
    this.hasClone = false;

    // If there's no carousel element, or if the carousel has already been initialized, get out.
    if (!this.carouselEl || this.carouselEl.classList.contains('tns-slider')) {
      return;
    }

    this.init();
  }

  init() {
    // Tiny Slider options that aren't customizable.
    this.carousel = tns({
      ...this.options,
      nav: false,
      controls: false,
      container: this.carouselEl,
      onInit: this.onInit.bind(this),
    });

    this.setupControls();
    this.carousel.events.on('transitionStart', this.removeFocus.bind(this));
    this.carousel.events.on('transitionEnd', this.setFocus.bind(this));
  }

  onInit(carousel) {
    this.createClone(carousel);
    this.setFocus(carousel);
  }

  removeFocus({ slideItems }) {
    Array.from(slideItems).forEach(slide => slide.classList.remove('slide--focus'));
  }

  setFocus({ slideItems, index }) {
    const tabbable = this.carouselEl.querySelectorAll('a');
    tabbable.forEach(a => a.setAttribute('tabindex', '-1'));

    slideItems[index].classList.add('slide--focus');
    slideItems[index].querySelectorAll('a').forEach(a => a.removeAttribute('tabindex'));
  }

  createClone({ slideItems, slideCount }) {
    const cloneSlide = slideItems.item(slideCount - 1).cloneNode(true);
    cloneSlide.removeAttribute('id');
    cloneSlide.classList.add('tns-cloned-node');
    this.carouselEl.appendChild(cloneSlide);
  }

  setupControls() {
    const navButtons = this.el.querySelectorAll('button[data-goto]');
    navButtons.forEach(btn => {
      btn.addEventListener('click', () => this.carousel.goTo(btn.dataset.goto));
      btn.addEventListener('keyup.enter', () => this.carousel.goTo(btn.dataset.goto));
    });
  }
}

export default Carousel;
